import React, { Component } from "react";

import Button from "src/components/ui/Button";
import GameRounds from "./GameRounds";

import styles from "./Game.scss";

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameState: "BEFORE_START",
      totalRounds: 5,
      hasPlayedARound: false,
      gameOptions: {
        numberOfOptions: 3,
      },
    };
    this.startGame = this.startGame.bind(this);
    this.handleGameEnd = this.handleGameEnd.bind(this);
  }
  startGame() {
    const optionsEl = document.getElementById("options-selector");
    const numberOfOptions = optionsEl.value < 2 ? 2 : parseInt(optionsEl.value);

    const roundsEl = document.getElementById("rounds-selector");
    const totalRounds = roundsEl.value < 1 ? 1 : parseInt(roundsEl.value);
    const gameOptions = {
      numberOfOptions,
    };
    console.log(totalRounds);
    this.setState({
      gameState: "IN_GAME",
      hasPlayedARound: true,
      gameOptions,
      totalRounds,
    });
  }
  handleGameEnd(score) {
    this.setState({
      gameState: "BEFORE_START",
      score,
    });
  }
  render() {
    const {
      gameState,
      gameOptions,
      score,
      totalRounds,
      hasPlayedARound,
    } = this.state;
    return (
      <div styleName="wrap">
        {
          gameState === "BEFORE_START" ? (
            <div styleName="waiting">
              {hasPlayedARound && (
                <div styleName="score">
                  Score: {score} of {totalRounds}{' '}<span>({Math.trunc((100 * score) / totalRounds)}%)</span>
                </div>
              )}
              <div styleName="game-options">
                <div styleName="game-option">
                  <label>Choices</label>
                  <input
                    id="options-selector"
                    type="number"
                    defaultValue={gameOptions.numberOfOptions}
                  />
                </div>
                <div styleName="game-option">
                  <label>Questions</label>
                  <input
                    id="rounds-selector"
                    type="number"
                    defaultValue={totalRounds}
                  />
                </div>
              </div>
       
              <Button onClick={this.startGame}>
                {hasPlayedARound ? "Play Again" : "Start Game"}
              </Button>
            </div>
          ) : gameState === "IN_GAME" ? (
            <GameRounds
              gameOptions={gameOptions}
              totalRounds={totalRounds}
              onGameEnd={this.handleGameEnd}
            />
          ) : null // empty game state
        }
      </div>
    );
  }
}

Game.propTypes = {};

export default Game;
