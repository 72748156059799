import React from "react";
import classNames from "classnames";
import Button from "src/components/ui/Button";

import styles from './OptionButton.scss';

const OptionButton = ({
  children,
  number,
  index,
  onClick,
  wasSelected,
  wasCorrect,
  selectionOccurred,
}) => {
  const styleNames = classNames({
    selected: wasSelected,
    'not-selected': !wasSelected,
    correct: wasCorrect,
    incorrect: !wasCorrect,
    'has-selection': selectionOccurred,
    "option-button-wrap": true,
  });
  return (
    <div key={number} styleName={styleNames}>
      <Button onClick={onClick} className={styles["option-button"]}>
        {children}
      </Button>
    </div>
  );
};

export default OptionButton;
