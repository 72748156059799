import moment from 'moment';

import presidents from 'src/assets/data/presidents';
import President from 'src/classes/President';



function randomDate(start, end) {
  return new Date(start.valueOf() + Math.random() * (end.valueOf() - start.valueOf()));
}


const QUESTION_MODES = {
  ON_THIS_DATE: 'ON_THIS_DATE',
  PRESIDENT_BEFORE: 'PRESIDENT_BEFORE',
  PRESIDENT_AFTER: 'PRESIDENT_AFTER',
};
const QUESTION_MODES_AVAILABLE = [
  QUESTION_MODES.ON_THIS_DATE,
  // QUESTION_MODES.PRESIDENT_BEFORE,
  QUESTION_MODES.PRESIDENT_AFTER,
];

const pickRandom = (arr = []) => arr[Math.trunc(Math.random() * arr.length)];
const removeRandom = (arr = []) => arr.splice(Math.trunc(Math.random() * arr.length), 1)[0];

class PresidentList {
  constructor() {
    this.presidents = presidents.slice(0).map((p) => new President(p));
  }
  getRandomPresidents(amount = 5, exclude = 0) {
    const pickFrom = this.presidents.filter(({ number }) => number !== exclude);
    const ret = [];
    while (ret.length < amount) {
      const removed = removeRandom(pickFrom);
      ret.push(removed);
    }
    return ret;
  }
  getRound({
    numberOfOptions,
  }) {
    this.candidates = this.getRandomPresidents(numberOfOptions);
    const randomMode = pickRandom(QUESTION_MODES_AVAILABLE);

    if (randomMode === QUESTION_MODES.ON_THIS_DATE) {
      const answer = pickRandom(this.candidates);
      const dateInQuestion = randomDate(answer.tookOffice, answer.leftOffice);
      const question = `Who was president in ${moment(dateInQuestion).format('MMMM [of] YYYY')}?`;
      return {
        answer, 
        question,
        presidents: this.candidates,
      };
    } else if (randomMode === QUESTION_MODES.PRESIDENT_AFTER) {
      let answer = pickRandom(this.candidates);

      const firstPresident = 1;
      while(answer.number === firstPresident) {
        answer = pickRandom(this.candidates);
      }

      const presidentBefore = this.presidents.find((p) => p.number === answer.number - 1);
      const question = `Who was the president after ${presidentBefore.name}?`;
      return {
        answer, 
        question,
        presidents: this.candidates,
      };
    } else if (randomMode === QUESTION_MODES.PRESIDENT_BEFORE) {
      let answer = pickRandom(this.candidates);

      const lastPresident = 45;
      while(answer.number === lastPresident) {
        answer = pickRandom(this.candidates);
      }

      const presidentAfter = this.presidents.find((p) => p.number === answer.number + 1);
      const question = `Who was the president before ${presidentAfter.name}?`;
      return {
        answer, 
        question,
        presidents: this.candidates,
      };
    }
    return {
      question: 'error',
    };
  }

}

export default new PresidentList();