import React, { Component } from "react";
import { motion, AnimatePresence } from "framer-motion";

import OptionButton from "src/components/ui/OptionButton";


import PresidentList from "src/classes/PresidentList";
import styles from "./Game.scss";

class GameRounds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round: 0,
      score: 0,
      hasPlayedARound: false,
      selectedIndex: -1,
      presidents: [],
    };
    this.selectAnswer = this.selectAnswer.bind(this);
    this.blockInputs = false;
  }
  componentDidMount() {
    this.startGame();
  }
  startGame() {
    const { question, answer, presidents } = PresidentList.getRound(this.props.gameOptions);

    this.setState({
      presidents,
      question,
      answer,
      round: 0,
      score: 0,
      selectedIndex: -1,
    });
  }
  selectAnswer(number, index) {
    const { answer, score } = this.state;
    let nextScore = score;
    let wasCorrect = false;
    if (this.blockInputs) {
      return;
    }
    this.blockInputs = true;
    if (number === answer.number) {
      nextScore += 1;
      wasCorrect = true;
    }

    this.setState(
      {
        score: nextScore,
      },
      () => {
        this.goToNextRound(wasCorrect, index);
      },
    );
  }
  goToNextRound(wasCorrect, index) {
    const { totalRounds } = this.props;
    const { round, score } = this.state;
    
    let nextRound = round + 1;
    console.log(totalRounds, round);
    this.setState({
      wasCorrect,
      selectedIndex: index,
    });
    setTimeout(() => {
      this.blockInputs = false;
      if (nextRound === totalRounds) {
        this.props.onGameEnd(score);
      } else {
        const { question, answer, presidents } = PresidentList.getRound(this.props.gameOptions);
        this.setState({
          round: nextRound,
          presidents,
          question,
          answer,
          wasCorrect: false,
          selectedIndex: -1,
        });
      }
    }, 1000);
  }
  render() {
    const { totalRounds } = this.props;

    const {
      presidents,
      question,
      round,
      selectedIndex,
      answer,
    } = this.state;
    return (
      <motion.div
        styleName="game-round"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
      >
        <div styleName="round">
          Question {round + 1} of {totalRounds}
        </div>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={round}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
          >
            <div styleName="question">
              <span>{question}</span>
            </div>
          </motion.div>
        </AnimatePresence>
        <div styleName="options">
          <AnimatePresence exitBeforeEnter>
            {presidents.map(({ name, number }, index) => (
              <motion.div
                key={number}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 10, opacity: 0 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.3,
                  delay: index * 0.05,
                }}
              >
                <OptionButton
                  onClick={() => this.selectAnswer(number, index)}
                  wasCorrect={number === answer.number}
                  wasSelected={selectedIndex === index}
                  selectionOccurred={selectedIndex >= 0}
                >
                  {name}
                </OptionButton>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </motion.div>
    );
  }
}

GameRounds.propTypes = {};

export default GameRounds;
