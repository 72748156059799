import React, { Component } from 'react';
import styles from './Button.scss';

class Button extends Component {
  static defaultProps = {
    className: '',
    onClick: () => {},
  }
  render() {
    return (
      <button onClick={this.props.onClick} className={this.props.className} styleName="button">
        {this.props.children}
      </button>
    );
  }
}

export default Button;
