import moment from 'moment';

export default class President {
  constructor({
    number, president, birth_year, death_year, took_office, left_office, party,
  }) {
    this.number = number;
    this.name = president;
    this.birthYear = birth_year;
    this.deathYear = death_year;
    this.tookOffice = moment(took_office, 'YYYY-MM-DD');
    this.leftOffice = moment(left_office, 'YYYY-MM-DD');
    this.party = party;
  }
}