import React, { Component } from "react";
import "./scss/main.scss";

import Game from "src/components/Game";

function setDocHeight() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight / 100}px`,
  );
  document.documentElement.dataset["vh"] = window.innerHeight;
}
class App extends Component {
  componentDidMount() {
    setDocHeight();
  }
  render() {
    return <Game />;
  }
}
export default App;
